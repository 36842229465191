<!--  -->
<template>
  <div>
      <!-- 盘点类型 -->
      <van-sticky >
      <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <!-- <div class="point_kind">{{ detailsList.inventoryName }}</div> -->
        <div>详情明细</div>
        <div class="point_point">…</div>
      </div>
    </van-sticky>
    <div style="background:rgba(225, 224, 223, 0.2);height: 100vh;">
    
      <!-- main -->
      <div class="details_main" style="font-size: 3.5vw;">
        <div style="display:flex;width: 50vw;height:5vw;align-items: center;margin-left: 5vw;">
          <div style="width:1.2vw;height:4.5vw;background:#04A9F3"></div>
          <div style="margin-left:2vw;font-size:4vw;color:black;font-weight:600;">{{detailsList.inventoryName}}</div>
        </div>
       
        <!-- 门店名称  门店仓库  创建时间 -->
        <div class="picture" style="margin-top:1vw;display: flex;justify-content: center;align-items: center;">
       <div style="width:96%;height:18vw;display: flex;flex-direction: column;justify-content: space-between;">
        <div>
            <span style="margin-left:7vw; font-size:3.5vw">门店名称</span>
            <span style="margin-left: 5vw; font-size:3.5vw; font-weight: bolder" >{{ detailsList.shop }}</span>
          </div>
          <div style="width:81vw;border-bottom: 1px solid rgba(227, 227, 227, 0.5);margin-left:7vw;"></div>
          <div>
            <span style="margin-left:7vw; font-size: 3.5vw">门店仓库</span>
            <span style="margin-left: 5vw; font-size:3.5vw; font-weight: bolder">{{ detailsList.depository }}</span>
          </div>
          <div style="width:81vw;border-bottom: 1px solid rgba(227, 227, 227, 0.5);margin-left:7vw;"></div>
          <div>
            <span style="margin-left:7.3vw; font-size:3.5vw">盘点方式</span>
            <span style="margin-left: 5vw; font-size: 3.5vw; font-weight: bolder">{{ detailsList.inventoryMethodName }}</span>
          </div>
       </div> 
        </div>
         <!-- 输入货号  或者条码扫描 -->
         <div style="width:96%; height: 10vw; display: flex; align-items: center;justify-content:space-between; margin-left:2vw;padding:1.5vw 0" >
            <el-input  size="small" type="number" placeholder="请输入货号"
              style="background: none; border-radius: 2vw; width: 50vw; height: 7.5vw; font-size: 3.5vw;border-radius: 3.7vw;font-weight: 600;" @focus="getFocus()" v-model="keyword" />
            <el-button @click="searchCode()" type="primary"
              style=" width:20vw; height: 8vw;display: flex; align-items: center;justify-content: center;margin-left: 20vw;margin-top: 0.6vw;
              background: #3DBFF9;
              border: 0;
              border-radius:7vw;
              padding:4vw;"
              v-if="queryMa">条码扫描</el-button>
            <el-button type="primary"
              style="width:30vw; height: 8vw;display: flex; align-items: center;justify-content: center; margin-top: 0.6vw;margin-left:20vw;
              background: #3DBFF9;
              border: 0;
              border-radius:7vw;
              padding:4vw;"
              @click="search()"
              v-show="queryPoint">查询</el-button >
      </div>
        <!-- left -->
        <!-- <div class="point_main_left" style=" width: 70%; height: 100%; display: flex; flex-direction: column; justify-content: space-around; color: black; font-weight: 600;" >
          <div>
            <span style="margin-left: 3vw; font-size: 5vw">门店名称</span>
            <span style="margin-left: 5vw; font-size: 5vw; font-weight: bolder" >{{ detailsList.shop }}</span>
          </div>
          <div>
            <span style="margin-left: 3vw; font-size: 5vw">门店仓库</span>
            <span style="margin-left: 5vw; font-size: 5vw; font-weight: bolder">{{ detailsList.depository }}</span>
          </div>
          <div>
            <span style="margin-left: 3vw; font-size: 5vw">盘点方式</span>
            <span style="margin-left: 5vw; font-size: 5vw; font-weight: bolder">{{ detailsList.inventoryMethodName }}</span>
          </div>
          
          <div style="width: 75vw; height: 10vw; display: flex; align-items: center;justify-content: space-around; margin-left: -2vw;">
            <el-input size="medium" type="number" placeholder="请输入货号"
              style="margin-left: 3vw; background: none; border-radius: 2vw; width: 50vw; height: 7.5vw; font-size: 5.5vw; " @focus="getFocus()" v-model="keyword" />
            <el-button @click="searchCode()" type="primary"
              style=" width: 16vw; height: 8vw;display: flex; align-items: center;justify-content: center; margin-top: 0.6vw;"
              v-if="queryMa">条码扫描</el-button>
            <el-button type="primary"
              style=" width: 16vw; height: 8vw;display: flex; align-items: center;justify-content: center; margin-top: 0.6vw;"
              @click="search()"
              v-show="queryPoint">查询</el-button >
          </div>
        </div> -->
      </div>
      <!-- 输入货号   条码扫描 -->
      <!-- <div style="display:flex;width: 50vw;height:5vw;align-items: center;margin-left:8vw;">
          <div style="width:1.2vw;height:4.5vw;background:#04A9F3"></div>
          <div style="margin-left:2vw;font-size:4vw;color:black;font-weight:600;">输入货号或条码扫描</div>
        </div> -->
      
      <!-- 盘入数量 -->
      <!-- <div style="display:flex;width: 50vw;height:5vw;align-items: center;margin-left:8vw;margin-top: 1vw;" v-if="invent">
          <div style="width:1.2vw;height:4.5vw;background:#04A9F3"></div>
          <div style="margin-left:2vw;font-size:4vw;color:black;font-weight:600;">盘入数量</div>
        </div> -->
      <div v-if="invent"
        style="margin-top:0.5vw; width: 90%;  margin-left: 5%; display: flex; flex-direction: column;justify-content:space-between; align-items: right;">
   
        <div v-if="detailsList.inventoryMethodName == '批号盘点'"
          style=" display: flex; align-items: center; font-size: 4.5vw;">
          <div v-show="pihaoT">
           <el-select
              v-model="value"
              placeholder="请选择批号"
              size="small"
              style="width: 46.5vw; font-weight: bold"
              @change="Selection(value)"
            >
              <el-option
                v-for="item in optionsList"
                :key="item.batchNo"
                :label="`${item.batchNo} , ${item.expiryDate}`"
                :value="item.batchNo"
              >
              </el-option>
            </el-select>
          </div>
          <div style="display: flex; align-items: center" v-show="handpihao">
            <el-input
              size="small"
              type="number"
              placeholder="请输入商品批号"
              v-model="from.itemPiNo"
              autofocus="autofocus"
              style="width: 46.5vw; font-weight: bold"
            />
          </div>
          <el-button
            v-show="inp"
            type="primary"
            style="
              height:7vw;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20vw;
              margin-left: 23vw;
              border-radius:5.5vw;
              padding:4vw;
              background: #3DBFF9;
              border: 0;
            "
            @click="pihaoSumit()"
            >输入批号</el-button
          >
          <el-button
            @click="pihaoSumit1()"
            v-show="look"
            type="primary"
            style="
              height: 6vw;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20vw;
              margin-left: 23vw;
              background: #3DBFF9;
              border: 0;
              border-radius:5.5vw;
              padding:4vw;
            "
            >选择批号</el-button
          >
        </div>

        <!-- <div class="block">
            <span class="demonstration">商品批号：</span>
            <el-cascader
               size="small"
              :placeholder="item"
              v-model="value"
              :options="optionsList.batchNo"
              @change="handleChange"
              style="width: 47vw;height:2vw; font-weight: bold;"
            ></el-cascader>
          </div> -->
        <!-- 商品批号：<input
            type=number
            placeholder="请输入商品批号"
            v-model="from.itemPiNo"
            autofocus="autofocus"
            style="width: 45vw;font-weight: bold;"
          /> -->

        <div
          style=" display: flex;
            align-items: center;
            font-size: 4.5vw;
           
          "
        >
          <!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
          <el-input
            size="small"
            type="number"
            placeholder="请输入商品数量"
            v-model="from.number1"
            autofocus="autofocus"
            style="width: 46.5vw; font-weight: bold;margin-top:2vw;"
          />
          <el-button
            v-show="submit00"
            type="primary"
            style="
              height: 6vw;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20vw;
              margin-left: 23vw;
              background: #3DBFF9;
              border: 0;
              border-radius:5.5vw;
              padding:4vw;
              margin-top:2vw;
            "
            @click="submit()"
            >保&nbsp;&nbsp;存</el-button
          >
          <el-button
            v-show="submit11"
            type="primary"
            style="
              height: 6vw;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20vw;
              margin-left: 23vw;
              background: #3DBFF9;
              border: 0;
              border-radius:5.5vw;
              padding:4vw;
              margin-top:2vw;
            "
            @click="submit1()"
            >保&nbsp;&nbsp;存</el-button
          >
        </div>

        <!-- detailsList.inventoryMethodName=='批号盘点'&& -->
      </div>
      <!-- 分割线 -->
      <!-- <el-divider></el-divider> -->
      <!-- 商品信息 -->
      <div style="display:flex;width: 50vw;height:5vw;align-items: center;margin-left:8vw;margin-top:2vw;" v-if="dis">
          <div style="width:1.2vw;height:4.5vw;background:#04A9F3"></div>
          <div style="margin-left:2vw;font-size:4vw;color:black;font-weight:600;">商品信息</div>
      </div>
      <div style="font-size: 4vw;margin-top: 2vw;" v-if="dis">
        <div class="picture1" style="margin-top:1vw;display: flex;justify-content: center;align-items: center;margin-left: 3.5vw;">
       <div style="width:96%;height:18vw;display: flex;flex-direction: column;justify-content: space-between;">
       <!-- 蓝色框 -->
       <div style="width:96%;height:10vw;background:#23B5F6;border-radius:5vw;margin-left: 3%;display: flex;justify-content: space-around;align-items: center;color: white;font-size: 3vw;">
        <div>货号</div>
        <div style="border:0.01px solid white;height:3.5vw"></div>
        <div>品名</div>
        <div style="border:0.01px solid white;height:3.5vw"></div>
        <div>规格</div>
        <div style="border:0.01px solid white;height:3.5vw"></div>
        <div>单位</div>
        <div style="border:0.01px solid white;height:3.5vw"></div>
        <div>生产单位</div>
      </div>
       <!-- 商品信息 -->
       <div style="width:96%;height:10vw;border-radius:5vw;margin-left: 3%;display: flex;justify-content: space-around;align-items: center;color: black;font-size: 3vw;">
        <div>
          
             <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.货号 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width:6.8vw">
                  {{ goodDetailList.货号 }}
                </div>
              </el-tooltip>
        </div>
        <div ></div>
        <div>  <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.品名 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width:10vw">
                  {{ goodDetailList.品名 }}
                </div>
              </el-tooltip></div>
        <div ></div>
        <div><el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.规格 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 13vw">
                  {{ goodDetailList.规格 }}
                </div>
              </el-tooltip></div>
        <div ></div>
        <div><el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.单位 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width:8vw">
                  {{ goodDetailList.单位 }}
                </div>
              </el-tooltip></div>
        <div ></div>
        <div><el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.生产单位 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 12.9vw">
                  {{ goodDetailList.生产单位 }}
                </div>
              </el-tooltip></div>
      </div>
       </div> 
        </div>
        <!-- <table
          border="1"
          cellspacing="0"
          width="90%"
          v-if="dis=true"
          style="margin-left: 5%"
        >
          <tr>
            <td
              colspan="5"
              style="font-size: 6vw; font-weight: bolder"
              align="center"
            >
              商品信息
            </td>
          </tr>
          <tr style="font-size: 4.2vw">
            <td width="20%" align="center">货号</td>
            <td width="20%" align="center">品名</td>
            <td width="20%" align="center">规格</td>
            <td width="20%" align="center">单位</td>
            <td width="20%" align="center">生产单位</td>
          </tr>
          <tr style="font-size: 4.2vw">
            <td width="20%" align="center">
              <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.货号 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 16vw">
                  {{ goodDetailList.货号 }}
                </div>
              </el-tooltip>
            </td>
            <td width="20%" align="center">
              <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.品名 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 16vw">
                  {{ goodDetailList.品名 }}
                </div>
              </el-tooltip>
            </td>
            <td width="20%" align="center">
              <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.规格 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 17vw">
                  {{ goodDetailList.规格 }}
                </div>
              </el-tooltip>
            </td>
            <td width="20%" align="center">
              <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.单位 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 16vw">
                  {{ goodDetailList.单位 }}
                </div>
              </el-tooltip>
            </td>
            <td width="5%" align="center">
              <el-tooltip placement="top">
                <div slot="content">{{ goodDetailList.生产单位 }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 16vw">
                  {{ goodDetailList.生产单位 }}
                </div>
              </el-tooltip>
            </td>
          </tr> -->
        <!-- </table> -->
      </div>
      <!--盘入数据展示  -->

     
      <div style="display:flex;width: 50vw;height:5vw;align-items: center;margin-left:8vw;margin-top: 1vw;">
          <div style="width:1.2vw;height:4.5vw;background:#04A9F3"></div>
          <div style="margin-left:2vw;font-size:4vw;color:black;font-weight:600;">盘入数据展示</div>
        </div>
         <!-- 信息展示列表 -->
      <div style="font-size: 4vw; margin-top: 4vw" >
        <div style="width:90%;height:auto;background:#fff;margin-left:5%;border-radius: 3vw;border: 1px solid rgba(227, 227, 227, 0.5);padding: 0 0 2vw 0;" >
         <div style="width:96%;height:10vw;background:#23B5F6;border-radius:5vw;margin-left:2%;display: flex;justify-content: space-around;align-items: center;color: white;font-size: 3vw;margin-top: 2vw;">
         
          <div style="margin-left:3vw">货号</div>
         
          <div style="margin-left:3vw" v-if="sign === 1">批号</div>
          
          <div style="margin-left:2vw">品名</div>
          
          <div style="margin-left:2vw" v-if="show === 1">账面</div>
       
          <div >输入数量</div>
        
          <div>操作</div>
         </div>
         <div style="width:96%;height:auto;border-radius:5vw;margin-left:2%;display: flex;justify-content: space-around;align-items: center;color: #000;font-size: 3vw;margin-top: 2vw;" >
         
         <table 
         cellspacing="0"
         width="96%"
   

         >
         
         <tr v-for="(item, index) in sbList" :key="index">
           <td width="20%" align="center">
            <el-tooltip placement="top">
               <div slot="content">{{ item.item_no }}</div>
               <div style="overflow: hidden; white-space: nowrap; width:14vw">
                {{ item.item_no }}
               </div>
             </el-tooltip>
            
            </td>
           <td width="20%" align="center" v-if="sign === 1">
             
             <el-tooltip placement="top">
               <div slot="content">{{ item.batch_no }}</div>
               <div style="overflow: hidden; white-space: nowrap; width:5vw">
                {{ item.batch_no }}
               </div>
             </el-tooltip>
           </td>
           <td width="20%" align="center" style="margin-right:20vw">
             <el-tooltip placement="top" >
               <div slot="content">{{ item.common_name }}</div>
               <div style="overflow: hidden; white-space: nowrap; width: 14vw">
                 {{ item.common_name }}
               </div>
             </el-tooltip>
           </td>
           <td width="20%" align="center" v-if="show === 1">
             {{ item.book_quantity }}
           </td>
           <td width="20%" align="center" >{{ item.real_quantity }}</td>
           <td width="20%" align="center"><div style="color:green;width: 10vw;" @click="modify(item)" >编辑</div></td>
         </tr>
         </table>
    
       </div>
        
        </div>
      
        <!-- <table
          border="1"
          cellspacing="0"
          width="90%"
          v-if="dia"
          style="margin-left: 5%"
        >
          <tr>
            <td
              colspan="5"
              style="font-size: 6vw; font-weight: bolder"
              align="center"
            >
              盘入数据展示
            </td>
          </tr>
          <tr>
            <td width="20%" align="center">货号</td>
            <td width="20%" align="center" v-if="sign === 1">批号</td>
            <td width="20%" align="center">品名</td>
            <td width="20%" align="center" v-if="show === 1">账面数量</td>
            <td width="20%" align="center">输入数量</td>
          </tr>
          <tr v-for="(item, index) in sbList" :key="index">
            <td width="20%" align="center">{{ item.item_no }}</td>
            <td width="20%" align="center" v-if="sign === 1">
              {{ item.batch_no }}
            </td>
            <td width="20%" align="center">
              <el-tooltip placement="top">
                <div slot="content">{{ item.common_name }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 17vw">
                  {{ item.common_name }}
                </div>
              </el-tooltip>
            </td>
            <td width="20%" align="center" v-if="show === 1">
              {{ item.book_quantity }}
            </td>
            <td width="20%" align="center">{{ item.real_quantity }}</td>
          </tr>
        </table> -->
      </div>
      <!-- 对话框 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="80%">
        <span>{{ message }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="enter1()">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 对话框 1-->
      <el-dialog title="提示" :visible.sync="dialog" width="80%">
        <span>{{ message }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="enter2()">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="请输入修改数量" :visible.sync="modifyList" width="80%">
        <el-input v-model="inputVal" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          
          <el-button type="primary" @click="enterList()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { number } from "echarts";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      value: [],
      item: "请选择批号",
      // options: [
      //   {
      //     value: "PAHDGYujsi234",
      //     label: "PAHDGYujsi234",
      //   },
      //   {
      //     value: "HDI43958945",
      //     label: "HDI43958945",
      //   },
      //   {
      //     value: "HIK2949",
      //     label: "HIK2949",
      //   },
      // ],
     
      detailsList: [],
      from: {
        itemPiNo: "", // 批号盘点 商品批号
        // itemNo1:"",//批号盘点 商品货号
        number1: "", //批号盘点 商品数量
      },
      keyword: "", //搜索关键词
      barCode: "",
      goodDetailList: [], //查询出来的商品详情
      dis: false, //商品信息框
      dia: true, //录入信息展示
      invent: false, //盘点框
      queryPoint: false, //查询
      queryMa: true, //扫码查询
      itNo: "",
      sbList: [], //信息展示list
      sign: "", //判断是批号盘点还是商品盘点
      show: "", //判断是否隐藏账面数量
      flag: true,
      dialogVisible: false,
      message: "",
      optionsList: [],
      pihaoT: true,
      handpihao: false,
      submit11: false,
      submit00: true,
      dialog: false,
      look: false,
      inp: true,
      modifyList:false,
      inputVal:""
    };
  },
  computed: {
    // // 统计打中的地鼠数量
    // keyword: function () {
    //   return this.result.join("");
    // },
  },
  mounted() {
    // let pihaoNo=localStorage.getItem('pihao')
    let id = this.$route.params.id;
    localStorage.setItem("id", id);
    // 从uniapp带回来的条形码
    let tmpBarCode = this.$route.params.barCode;
    this.detailList(id);
    this.information(id);

    var bar_code = localStorage.getItem("bar_code_" + tmpBarCode);
    // console.log("判断是否存在 Cache BarCode:",bar_code)

    // 缓存存在
    if (bar_code != null) {
      localStorage.removeItem("bar_code_" + tmpBarCode);
      console.log("barCode:", localStorage.getItem("bar_code_" + tmpBarCode));
    } else {
      console.log("不存在 barCode:");
      this.code1(tmpBarCode);
    }
  },
  methods: {
    // 修改盘入数量
    modify(index){
     
    this.modifyList=true
    console.log(index.id,"下表")
    localStorage.setItem("xiabiao",index.id)
    },
    // 修改数量确定按钮
    enterList(){
      
      if(this.inputVal==""){
        this.$toast({
              message: "请输入修改数量",
              duration: 1000,
            });
            return false
      } 
     
      this.$axios
        .post(this.$api.syncAppendQuantityList, {
          did:localStorage.getItem("xiabiao"),
          realQuantity:this.inputVal
        })
        .then((res) => {
          console.log(res, "修改详情数量");
     
        });
        location. reload() 
        this.modifyList=false
        
    console.log(this.inputVal,"要修改的数量")
    },
    pihaoSumit1() {
      this.pihaoT = true;
      this.handpihao = false;
      this.submit11 = false;
      this.submit00 = true;
      this.inp = true;
      this.look = false;
    },
    pihaoSumit() {
      this.pihaoT = false;
      this.handpihao = true;
      this.submit11 = true;
      this.submit00 = false;
      this.inp = false;
      this.look = true;
    },
    option(id) {
      // this.invent = true;
      console.log("llllll");
      this.$axios
        .get(this.$api.options666, {
          params: {
            inventoryPlanId: id,
            itemNo: this.keyword,
          },
        })
        .then((res) => {
          this.optionsList = res.data.result;
          console.log(this.optionsList, "select");
        });
    },
    option1(word, id) {
      // this.invent = true;
      console.log("llllll");
      this.$axios
        .get(this.$api.options666, {
          params: {
            inventoryPlanId: id,
            itemNo: word,
          },
        })
        .then((res) => {
          this.optionsList = res.data.result;
          console.log(this.optionsList, "select");
        });
    },
    Selection(item) {
      console.log(item, "vvvvv");
      this.pihao = item;
      localStorage.setItem("pihao", this.pihao);
    },
    cancel() {
      this.dis = false;
      this.invent = false;
      //this.barCode = null;
      localStorage.setItem("bar_code_" + this.barCode, this.barCode);
      this.keyword = "";
      (this.from.itemPiNo = ""),
        (this.from.number1 = ""),
        (this.dialogVisible = false);
    },
    enter2() {
      // let pihao= localStorage.getItem("pihao");
      // console.log("Aaaaaaa")
      // console.log(itemPiNo,"this.from.itemPiNo")

      this.$axios
        .post(this.$api.syncRealityQuantityList, {
          iid: this.detailsList.id,
          itemNo: this.goodDetailList.货号,
          batchNo: localStorage.getItem("itemPiNo"),
          realQuantity: this.from.number1,
          type: this.detailsList.inventoryMethod,
          inventoryType: this.detailsList.inventoryType,
          itemType: this.goodDetailList.类别,
          // InventoryType:inventoryId,
        })
        .then((res) => {
          console.log(res, "批号盘点");
          if (res.data.code === 0 || res.data.msg === "success") {
            // this.$message({
            //   message: "保存成功",
            //   type: "success",
            //   duration:3000,
            // });
            //  this.$toast("请输入正确的验证码，且不能有空值!");
            this.$toast({
              message: "保存成功",
              type: "success",
              duration: 3000,
            });
            location.reload();
          } else if (res.data.code === 500) {
            this.$toast({ message: res.data.msg, duration: 3000 });

            return false;
          }
        });

      this.dis = false;
      this.invent = false;
      //this.barCode = null;
      localStorage.setItem("bar_code_" + this.barCode, this.barCode);
      this.keyword = "";
      this.dialog = false;
    },
    enter1() {
      let pihao = localStorage.getItem("pihao");
      console.log(pihao);
      // let pihao = localStorage.getItem("pihao");
      this.$axios
        .post(this.$api.syncRealityQuantityList, {
          iid: this.detailsList.id,
          itemNo: this.goodDetailList.货号,
          batchNo: pihao,
          realQuantity: this.from.number1,
          type: this.detailsList.inventoryMethod,
          inventoryType: this.detailsList.inventoryType,
          itemType: this.goodDetailList.类别,
          // InventoryType:inventoryId,
        })
        .then((res) => {
          console.log(res, "批号盘点");
          if (res.data.code === 0 || res.data.msg === "success") {
            // this.$message({
            //   message: "保存成功",
            //   type: "success",
            //   duration:3000,
            // });
            //  this.$toast("请输入正确的验证码，且不能有空值!");
            this.$toast({
              message: "保存成功",
              type: "success",
              duration: 3000,
            });
            location.reload();
          } else if (res.data.code === 500) {
            this.$toast({ message: res.data.msg, duration: 3000 });

            return false;
          }
        });

      this.dis = false;
      this.invent = false;
      //this.barCode = null;
      localStorage.setItem("bar_code_" + this.barCode, this.barCode);
      this.keyword = "";
      this.dialogVisible = false;
    },
    enter(pihao) {
      // let pihao = localStorage.getItem("pihao");
      this.$axios
        .post(this.$api.syncRealityQuantityList, {
          iid: this.detailsList.id,
          itemNo: this.goodDetailList.货号,
          batchNo: pihao,
          realQuantity: this.from.number1,
          type: this.detailsList.inventoryMethod,
          inventoryType: this.detailsList.inventoryType,
          itemType: this.goodDetailList.类别,
          // InventoryType:inventoryId,
        })
        .then((res) => {
          console.log(res, "批号盘点");
          if (res.data.code === 0 || res.data.msg === "success") {
            // this.$message({
            //   message: "保存成功",
            //   type: "success",
            //   duration:3000,
            // });
            //  this.$toast("请输入正确的验证码，且不能有空值!");
            this.$toast({
              message: "保存成功",
              type: "success",
              duration: 3000,
            });
            location.reload();
          } else if (res.data.code === 500) {
            this.$toast({ message: res.data.msg, duration: 3000 });

            return false;
          }
        });

      this.dis = false;
      this.invent = false;
      //this.barCode = null;
      localStorage.setItem("bar_code_" + this.barCode, this.barCode);
      this.keyword = "";
      this.dialogVisible = false;
    },
    //点击跳转到小程序壳上扫码
    searchCode() {
      //带着用户token跳转到小程序壳
      wx.miniProgram.navigateTo({
        url:
          "/pages/code/code?iid=" +
          localStorage.getItem("id") +
          "&token=" +
          localStorage.getItem("token"),
      });
    },
    // 信息展示列表
    information(id) {
      this.$axios
        .get(this.$api.unCalContList, {
          params: {
            iid: id,
          },
        })
        .then((res) => {
          if (res.data.code === 0 || res.data.msg === "success") {
            
            console.log(res, "新加的");
            this.sbList = res.data.unCalContList;
            this.sign = res.data.sign;
            this.show = res.data.unCalContList[0].is_show;
            console.log(this.show, "this.show");
            return false;
          }
        });
    },
    //获取焦点触发的事件
    getFocus() {
      // console.log("aaaaaaa")
      this.queryPoint = true;
      this.queryMa = false;

      // this.dia=false;
    },
    // 搜索
    search() {
      console.log(this.keyword, "ssssssssssss");
      this.queryPoint = false;
      this.queryMa = true;

      console.log(this.keyword, "dlf,");
      // localStorage.setItem("key", this.keyword);
      if (this.keyword === "") {
        this.$toast({ message: "输入不能为空", duration: 3000 });
        return false;
      }
    
      this.$axios
        .get(this.$api.goodsList, {
          params: {
            goodsCode: this.keyword,
            /// barCode:this.barCode
          },
        })
        .then((res) => {
          //  console.log(res,"dskfjkl")
          if (res.data.code === 0 || res.data.msg === "success") {
            console.log(res,"800011")
            this.goodDetailList = res.data.goodsInfo[0];
            this.dis = true;
            this.invent = true;
          }

          console.log(this.goodDetailList, "d123lAAAAAAAAAAAAA");
          console.log(this.goodDetailList.类别, "d123lXXXXXXXXXXXX");
        });

      if (this.keyword != "") {
        this.option(this.$route.params.id);
      }
    },

    code1(e) {
      console.log(this.keyword, "ssssssssssss");
      this.queryPoint = false;
      this.queryMa = true;

      if (e == null || e == "undefined") {
        return false;
      }
      this.$axios
        .get(this.$api.goodsList, {
          params: {
            barCode: e,
          },
        })
        .then((res) => {
          if (res.data.code === 0 || res.data.msg === "success") {
            this.goodDetailList = res.data.goodsInfo[0];
            this.number = res.data.goodsInfo[0].货号;
            this.keyword = this.number;
            this.option1(this.keyword, this.$route.params.id);
            this.dis = true;
            this.invent = true;
            this.barCode = e;
          }
        });
    },
    submit() {
      let pihao = localStorage.getItem("pihao");
      console.log(pihao,"pihao")
      // console.log(pihao,"pihao")
      // // console.log(pihao, "dddddddddddd");
      // console.log(this.goodDetailList.类别, "this.goodDetailList.类别");
      // console.log(this.keyword, "this.keyword");
      // console.log(this.detailsList.id, "this.detailsList");
      localStorage.setItem("iid", this.detailsList.id);
      if (this.detailsList.inventoryTypeName === "总部抽盘") {
        let flag1 = this.itNo.findIndex((item) => item === this.keyword);
        if (flag1 === -1) {
          this.$toast({ message: "输入的数据有问题！", duration: 3000 });
          return false;
        } else {
          this.$axios
            .post(this.$api.syncRealityQuantityList, {
              iid: this.detailsList.id,
              itemNo: this.goodDetailList.货号,
              batchNo: pihao,
              realQuantity: this.from.number1,
              type: this.detailsList.inventoryMethod,
              inventoryType: this.detailsList.inventoryType,
              itemType: this.goodDetailList.类别,
              // InventoryType:inventoryId,
            })
            .then((res) => {
              console.log(res, "批号盘点");
              if (res.data.code === 0 || res.data.msg === "success") {
                this.$toast({
                  message: "保存成功",
                  type: "success",
                  duration: 3000,
                });
                // this.dis = false;
                // this.invent = false;
                location.reload();
              } else if (res.data.code === 500) {
                this.$toast({ message: res.data.msg, duration: 3000 });

                return false;
              }
            });
          
          this.dis = false;
          this.invent = false;
          this.keyword = "";
          localStorage.setItem("bar_code_" + this.barCode, this.barCode);
        }
      } else if (this.detailsList.inventoryTypeName === "即时盘点") {
        this.$axios
          .post(this.$api.checkAvailable, {
            iid: this.detailsList.id,
            itemNo: this.goodDetailList.货号,
            batchNo: pihao,
            // realQuantity: this.from.number1,
            type: this.detailsList.inventoryMethod,
            // inventoryType: this.detailsList.inventoryType,
            // itemType: this.goodDetailList.类别,
            // InventoryType:inventoryId,
          })
          .then((res) => {
            if (res.data.msg == "此品未盘入，即将同步库存商品数量！") {
              this.enter();
            } else {
              this.dialogVisible = true;
              this.message = res.data.msg;
            }
          });
      } else if (this.detailsList.inventoryTypeName === "动态抽盘") {
        {
          this.$axios
            .post(this.$api.checkAvailable, {
              iid: this.detailsList.id,
              itemNo: this.goodDetailList.货号,
              batchNo: pihao,
              // realQuantity: this.from.number1,
              type: this.detailsList.inventoryMethod,
              // inventoryType: this.detailsList.inventoryType,
              // itemType: this.goodDetailList.类别,
              // InventoryType:inventoryId,
            })
            .then((res) => {
              if (res.data.msg == "此品未盘入，即将同步库存商品数量！") {
                this.enter(pihao);
              } else {
                this.dialogVisible = true;
                this.message = res.data.msg;
                // this.enter1(pihao)
              }
            });
            location.reload()
        }
      } else {
        this.$axios
          .post(this.$api.syncRealityQuantityList, {
            iid: this.detailsList.id,
            itemNo: this.goodDetailList.货号,
            batchNo: pihao,
            realQuantity: this.from.number1,
            type: this.detailsList.inventoryMethod,
            inventoryType: this.detailsList.inventoryType,
            itemType: this.goodDetailList.类别,
            // InventoryType:inventoryId,
          })
          .then((res) => {
            console.log(res, "批号盘点");
            if (res.data.code === 0 || res.data.msg === "success") {
              this.$toast({
                message: "保存成功",
                type: "success",
                duration: 3000,
              });
              location.reload();
            } else if (res.data.code === 500) {
              this.$toast({ message: res.data.msg, duration: 3000 });
              return false;
            }
          });

        this.dis = false;
        this.invent = false;
        //this.barCode = null;
        localStorage.setItem("bar_code_" + this.barCode, this.barCode);
        this.keyword = "";
      }
      // this.dia=true
    },
    submit1() {
      console.log(this.from.itemPiNo, "this.from");
      localStorage.setItem("itemPiNo", this.from.itemPiNo);
      //  console.log(itemPiNo,"itemPiNo")
      // let pihao = localStorage.getItem("pihao");
      // console.log(pihao,"pihao")
      // // console.log(pihao, "dddddddddddd");
      // console.log(this.goodDetailList.类别, "this.goodDetailList.类别");
      // console.log(this.keyword, "this.keyword");
      // console.log(this.detailsList.id, "this.detailsList");
      localStorage.setItem("iid", this.detailsList.id);
      if (this.detailsList.inventoryTypeName === "总部抽盘") {
        let flag1 = this.itNo.findIndex((item) => item === this.keyword);
        if (flag1 === -1) {
          this.$toast({ message: "输入的数据有问题！", duration: 3000 });
          return false;
        } else {
          this.$axios
            .post(this.$api.syncRealityQuantityList, {
              iid: this.detailsList.id,
              itemNo: this.goodDetailList.货号,
              batchNo: this.from.itemPiNo,
              realQuantity: this.from.number1,
              type: this.detailsList.inventoryMethod,
              inventoryType: this.detailsList.inventoryType,
              itemType: this.goodDetailList.类别,
              // InventoryType:inventoryId,
            })
            .then((res) => {
              console.log(res, "批号盘点");
              if (res.data.code === 0 || res.data.msg === "success") {
                this.$toast({
                  message: "保存成功",
                  type: "success",
                  duration: 3000,
                });
                // this.dis = false;
                // this.invent = false;
                location.reload();
              } else if (res.data.code === 500) {
                this.$toast({ message: res.data.msg, duration: 3000 });

                return false;
              }
            });

          this.dis = false;
          this.invent = false;
          this.keyword = "";
          localStorage.setItem("bar_code_" + this.barCode, this.barCode);
        }
      } else if (this.detailsList.inventoryTypeName === "即时盘点") {
        this.$axios
          .post(this.$api.checkAvailable, {
            iid: this.detailsList.id,
            itemNo: this.goodDetailList.货号,
            batchNo: this.from.itemPiNo,
            // realQuantity: this.from.number1,
            type: this.detailsList.inventoryMethod,
            // inventoryType: this.detailsList.inventoryType,
            // itemType: this.goodDetailList.类别,
            // InventoryType:inventoryId,
          })
          .then((res) => {
            if (res.data.msg == "此品未盘入，即将同步库存商品数量！") {
              this.enter();
            } else {
              this.dialogVisible = true;
              this.message = res.data.msg;
              localStorage.setItem("itemPiNo", this.from.itemPiNo);
            }
          });
      } else if (this.detailsList.inventoryTypeName === "动态抽盘") {
        {
          this.$axios
            .post(this.$api.checkAvailable, {
              iid: this.detailsList.id,
              itemNo: this.goodDetailList.货号,
              batchNo: this.from.itemPiNo,
              // realQuantity: this.from.number1,
              type: this.detailsList.inventoryMethod,
              // inventoryType: this.detailsList.inventoryType,
              // itemType: this.goodDetailList.类别,
              // InventoryType:inventoryId,
            })
            .then((res) => {
              if (res.data.msg == "此品未盘入，即将同步库存商品数量！") {
                this.enter(this.from.itemPiNo);
              } else {
                this.dialog = true;
                this.message = res.data.msg;
                // this.enter1(pihao)
              }
            });
        }
      } else {
        this.$axios
          .post(this.$api.syncRealityQuantityList, {
            iid: this.detailsList.id,
            itemNo: this.goodDetailList.货号,
            batchNo: this.from.itemPiNo,
            realQuantity: this.from.number1,
            type: this.detailsList.inventoryMethod,
            inventoryType: this.detailsList.inventoryType,
            itemType: this.goodDetailList.类别,
            // InventoryType:inventoryId,
          })
          .then((res) => {
            console.log(res, "批号盘点");
            if (res.data.code === 0 || res.data.msg === "success") {
              this.$toast({
                message: "保存成功",
                type: "success",
                duration: 3000,
              });
              location.reload();
            } else if (res.data.code === 500) {
              this.$toast({ message: res.data.msg, duration: 3000 });
              return false;
            }
          });

        this.dis = false;
        this.invent = false;
        //this.barCode = null;
        localStorage.setItem("bar_code_" + this.barCode, this.barCode);
        this.keyword = "";
      }
      // this.dia=true
    },
    // 详情list
    detailList(id) {
      this.$axios
        .get(
          "https://babb.boaiyiyao.com/api" +
            `/renren-fast/app/v1/inventory/inventoryInfo/${id}`,
          {}
        )
        .then((res) => {
          this.detailsList = res.data.inventory[0];
          console.log("detailsList:", this.detailsList);
          if (this.detailsList.inventoryTypeName === "总部抽盘") {
            let obj = JSON.parse(this.detailsList.queryParams);
            this.itNo = obj.itemNo.split(";");
          }
          // console.log(res, "iwsdxsdasda");
        });
    },
    // 返回上一级
    back() {
      this.$router.push(`/point_style/${this.detailsList.inventoryType}`);
    },
  },
};
</script>
<style lang='less' scoped>
</style>
<style>
input::-webkit-input-placeholder {
  position: relative;
  left: 3px;
  color: rgba(0, 0, 0, 0.1);
}
.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 13px 0;
}
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #443d3d;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_kind {
  font-weight: 700;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: bolder;
}
.details_main {
  width: 94%;
  height:auto;
  /* border-radius: 1vw; */
  margin-left: 3%;
  padding-top: 3vw;
  /* background-image: linear-gradient(#dfe3e5, #eef5f8) */
}
.picture{
  background: url(../assets/mipmap-xxxhdpi/矩形\ 4\ 拷贝\ 13.png) no-repeat;
  background-size: 100% 100% ;
  width:100%;
  height:27vw;
 

}
.picture1{
  background: url(../assets/mipmap-xxxhdpi/矩形\ 4\ 拷贝\ 13.png) no-repeat;
  background-size: 100% 100% ;
  width:93.5%;
  height:27vw;
 

}
.el-input--small .el-input__inner{
  border-radius: 16vw;
  width: 66vw;
}
.el-icon-arrow-up:before {
  content: "";
    margin-left: 20vw;
}
.el-select-dropdown__empty{
  width: 65.5vw;
}
.sousuo{
  background: url(../assets/mipmap-xxxhdpi/sousuo.png) no-repeat;
  background-size: 100% 100% ;
  width: 20vw;
  height: 20vw;
}
</style>